import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { registerUser } from "../../apiService/apiService";
import { COUNTRIES } from "../../constants/countries";
import "./Register.css";
import { RegisterData } from "../../constants/interfaces";

interface FormData {
  fullName: string;
  email: string;
  dateOfBirth: string;
  mobileNumber: string;
  password: string;
  confirmPassword: string;
  companyName: string;
  companyEmail: string;
  city: string;
  country: string;
  companyPhoneNumber: string;
  website: string;
}

const Register = () => {
  const [formData, setFormData] = useState<FormData>({
    fullName: "",
    email: "",
    dateOfBirth: "",
    mobileNumber: "",
    password: "",
    confirmPassword: "",
    companyName: "",
    companyEmail: "",
    city: "",
    country: "",
    companyPhoneNumber: "",
    website: "",
  });

  const [isLoading, setIsLoading] = useState<boolean>(false); // Loading state
  const [errorMessage, setErrorMessage] = useState<string>(""); // Error message state
  const navigate = useNavigate();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Check if passwords match
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    setIsLoading(true); // Start loading state
    setErrorMessage(""); // Clear previous error message

    try {
      const userData: RegisterData = {
        fullName: formData.fullName,
        email: formData.email,
        dateOfBirth: formData.dateOfBirth,
        mobilePhoneNumber: formData.mobileNumber,
        password: formData.password,
        companyName: formData.companyName,
        companyEmail: formData.companyEmail,
        city: formData.city,
        country: formData.country,
        companyPhoneNumber: formData.companyPhoneNumber,
        website: formData.website,
      };
      await registerUser(userData);
      navigate("/login");
    } catch (error: unknown) {
      console.error("Registration failed:", error);

      if (
        error instanceof Error &&
        error.message.includes("DuplicateUserName")
      ) {
        setErrorMessage("This email address is already registered.");
      } else {
        setErrorMessage(
          "An error occurred during registration. Please try again later.",
        );
      }
    } finally {
      setIsLoading(false); // Stop loading state
    }
  };

  return (
    <div
      className="register"
      style={{
        backgroundImage: "url(images/header/banner.png)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
      }}
    >
      <Link to="/">
        <img src="/images/logo/logo.svg" alt="Logo" className="logo" />
      </Link>
      <div className="container">
        <header>Registration</header>
        <form onSubmit={handleSubmit}>
          <div className="form first">
            <div className="details personal">
              <span className="title">Personal Details</span>
              <div className="fields">
                <div className="input-field">
                  <label>Full Name *</label>
                  <input
                    type="text"
                    name="fullName"
                    placeholder="Enter your name"
                    required
                    value={formData.fullName}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-field">
                  <label>Email *</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-field">
                  <label>Date of Birth *</label>
                  <input
                    type="date"
                    name="dateOfBirth"
                    required
                    value={formData.dateOfBirth}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-field">
                  <label>Phone Number *</label>
                  <input
                    type="number"
                    name="mobileNumber"
                    placeholder="Enter phone number"
                    required
                    value={formData.mobileNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-field">
                  <label>Password *</label>
                  <input
                    type="password"
                    name="password"
                    placeholder="Enter password"
                    required
                    value={formData.password}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-field">
                  <label>Confirm Password *</label>
                  <input
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm password"
                    required
                    value={formData.confirmPassword}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="details company">
              <span className="title">Company Details</span>
              <div className="fields">
                <div className="input-field">
                  <label>Company Name *</label>
                  <input
                    type="text"
                    name="companyName"
                    placeholder="Enter company name"
                    required
                    value={formData.companyName}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-field">
                  <label>Country *</label>
                  <select
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select a country</option>
                    {COUNTRIES.map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-field">
                  <label>Company Email (optional)</label>
                  <input
                    type="email"
                    name="companyEmail"
                    placeholder="Enter company email"
                    value={formData.companyEmail}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-field">
                  <label>City (optional)</label>
                  <input
                    type="text"
                    name="city"
                    placeholder="Enter city"
                    value={formData.city}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-field">
                  <label>Company Phone Number (optional)</label>
                  <input
                    type="text"
                    name="companyPhoneNumber"
                    placeholder="Enter phone number"
                    value={formData.companyPhoneNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-field">
                  <label>Website (optional)</label>
                  <input
                    type="text"
                    name="website"
                    placeholder="Enter website"
                    value={formData.website}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="input-field termsAndConditions">
                <label>
                  <input type="checkbox" name="termsAndConditions" required />I
                  agree to the <span> </span>
                  <Link to="/terms-and-conditions" target="_blank">
                    Terms and Conditions
                  </Link>
                </label>
              </div>

              <button className="nextBtn" type="submit" disabled={isLoading}>
                <span className="btnText">
                  {isLoading ? "Registering..." : "Register"}
                </span>
                <i className="uil uil-navigator" />
              </button>

              {/* Display error message if there's any */}
              {errorMessage && (
                <div
                  className="error-message"
                  style={{
                    color: "red",
                    marginTop: "10px",
                    textAlign: "center",
                  }}
                >
                  {errorMessage}
                </div>
              )}
            </div>
          </div>
        </form>
        <div className="sign-in-prompt">
          <p>
            Already have an account? <a href="/login">Sign In!</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
