import React, { useEffect, useState } from "react";
import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";
import SettingsInfoCard from "../../components/SettingsInfoCard/SettingsInfoCard";
import { getCompanySettings } from "../../apiService/apiService";
import Loader from "../../components/Loader/Loader";
import "./Settings.css";

interface Company {
  companyId: number;
  companyName: string;
  city: string;
  country: string;
  phoneNumber: string;
  email: string;
  website: string;
}

const Settings = () => {
  const [company, setCompany] = useState<Company | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await getCompanySettings();
        setCompany(response);
      } catch (err) {
        // Log the error to the console
        console.error("Error fetching company settings:", err);
        setError("Failed to load company settings");
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyData();
  }, []);

  if (loading) {
    return <Loader isLoading={loading} />;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="settings">
      <DashboardNavbar />
      <div className="content">
        {company ? (
          <SettingsInfoCard company={company} />
        ) : (
          <div>No company data available</div>
        )}
      </div>
    </div>
  );
};

export default Settings;
