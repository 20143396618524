import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./ResetPassword.css";
import {
  generateResetPasswordToken,
  validateResetPasswordToken,
  resetPassword,
} from "../../apiService/apiService";

const ResetPassword = () => {
  const [email, setEmail] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [step, setStep] = useState<number>(1); // Step 1: Email, Step 2: Code, Step 3: Reset Password
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  // Handle form submission for the email (Step 1)
  const handleEmailSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      await generateResetPasswordToken(email);
      setMessage("A verification code has been sent to your email.");
      setStep(2); // Move to the next step (enter code)
    } catch {
      setMessage("Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  // Handle form submission for the verification code (Step 2)
  const handleCodeSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      await validateResetPasswordToken(email, code);
      setMessage("Code verified! You can now reset your password.");
      setStep(3); // Move to password reset step
    } catch {
      setMessage("Invalid code. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Handle password reset submission (Step 3)
  const handleResetPasswordSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    setLoading(true);

    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match. Please try again.");
      setLoading(false);
      return;
    }

    try {
      await resetPassword(email, code, newPassword);
      setMessage("Your password has been successfully reset.");
      navigate("/login");
    } catch (error: unknown) {
      if (error instanceof Error) {
        setMessage(error.message);
      } else {
        setMessage("Something went wrong. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="reset-password"
      style={{
        backgroundImage: "url(images/header/banner.png)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
      }}
    >
      <Link to="/">
        <img src="/images/logo/logo.svg" alt="Logo" className="logo" />
      </Link>
      <div className="container">
        <header>Reset Password</header>
        {step === 1 ? (
          <form onSubmit={handleEmailSubmit}>
            <div className="fields">
              <div className="input-field">
                <label>Email</label>
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            </div>
            <button
              className="nextBtn"
              type="submit"
              disabled={!email || loading}
            >
              <span className="btnText">
                {loading ? "Sending..." : "Send Code"}
              </span>
              <i className="uil uil-navigator" />
            </button>
          </form>
        ) : step === 2 ? (
          <form onSubmit={handleCodeSubmit}>
            <div className="fields">
              <div className="input-field">
                <label>Verification Code</label>
                <input
                  type="text"
                  placeholder="Enter the code you received"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  required
                />
              </div>
            </div>
            <button
              className="nextBtn"
              type="submit"
              disabled={!code || loading}
            >
              <span className="btnText">
                {loading ? "Verifying..." : "Verify Code"}
              </span>
              <i className="uil uil-navigator" />
            </button>
          </form>
        ) : (
          <form onSubmit={handleResetPasswordSubmit}>
            <div className="fields">
              <div className="input-field">
                <label>New Password</label>
                <input
                  type="password"
                  placeholder="Enter new password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </div>
              <div className="input-field">
                <label>Confirm New Password</label>
                <input
                  type="password"
                  placeholder="Confirm new password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </div>
            </div>
            <button
              className="nextBtn"
              type="submit"
              disabled={!newPassword || !confirmPassword || loading}
            >
              <span className="btnText">
                {loading ? "Changing..." : "Reset Password"}
              </span>
              <i className="uil uil-navigator" />
            </button>
          </form>
        )}

        {message && <p className="message">{message}</p>}

        <div className="signup-prompt">
          <p>
            Don&apos;t have an account? <Link to="/register">Sign Up!</Link>
          </p>
          <p>
            <Link to="/login">Remember your password? Login</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
