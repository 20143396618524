export function getCurrentIsoTime() {
  const date = new Date();

  // Get the date and time in local ISO format without the timezone offset
  const isoDate =
    date.getFullYear().toString().padStart(4, "0") +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date.getDate().toString().padStart(2, "0") +
    "T" +
    date.getHours().toString().padStart(2, "0") +
    ":" +
    date.getMinutes().toString().padStart(2, "0") +
    ":" +
    date.getSeconds().toString().padStart(2, "0") +
    "." +
    date.getMilliseconds().toString().padStart(3, "0");

  return isoDate;
}

export function convertDateToIso(dateInput?: string): string {
  // If no date is provided, return the current date in ISO string format (UTC)
  const date = dateInput ? new Date(dateInput) : new Date();

  // Return the date in ISO format (which is always UTC)
  return date.toISOString();
}

export function formatIsoStringWithAmPm(isoString: string): string {
  // Create a Date object from the ISO string. This automatically adjusts to local time.
  const date = new Date(isoString);

  // Extract the components of the local time
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Months are 0-indexed, so add 1
  const day = date.getDate();
  let hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  // Convert hours to 12-hour format and determine AM or PM
  const amPm = hour >= 12 ? "PM" : "AM";
  hour = hour % 12;
  hour = hour ? hour : 12; // The hour '0' should be '12' (midnight)

  // Format the date and time as "YYYY-MM-DD hh:mm:ss AM/PM"
  const formattedDate = `${year.toString().padStart(4, "0")}/${month.toString().padStart(2, "0")}/${day.toString().padStart(2, "0")} ${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}:${second.toString().padStart(2, "0")} ${amPm}`;

  return formattedDate;
}

export function isMatchingDateCalendarDateWithISO(
  calendarDate: Date,
  gigDate: string,
): boolean {
  // Create a Date object for the gig date (which is in UTC) and convert it to local time
  const gigDateLocalTime = new Date(gigDate);

  // Extract the year, month, and day from both the local calendar date and the gig date (now in local time)
  const calendarYear = calendarDate.getFullYear();
  const calendarMonth = calendarDate.getMonth();
  const calendarDay = calendarDate.getDate();

  const gigYear = gigDateLocalTime.getFullYear();
  const gigMonth = gigDateLocalTime.getMonth();
  const gigDay = gigDateLocalTime.getDate();

  // Compare the year, month, and day of both dates
  return (
    calendarYear === gigYear &&
    calendarMonth === gigMonth &&
    calendarDay === gigDay
  );
}

export const formatISOToUKDay = (isoDateString: string): string => {
  // Create a Date object from the ISO string (which is in UTC)
  const date = new Date(isoDateString);

  // Use the DateTimeFormat API to format the date to the UK local timezone
  const ukDate = new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(date);

  return ukDate;
};

export const localDateInIsoFormat = (date: Date): string => {
  // Get the date in "sv-SE" format with time zone info
  const localeDateString = date
    .toLocaleString("sv-SE", { timeZoneName: "short" })
    .replace(" ", "T");

  // Extract the date and time parts
  const [datePart, timePart] = localeDateString.split("T");

  // Ensure the time is in HH:mm:ss format by padding the hours with leading zero if needed
  const [hours, minutes, seconds] = timePart.split(":");
  const formattedTime = `${String(hours).padStart(2, "0")}:${minutes}:${seconds}`;

  // Combine the date part, formatted time, and time zone
  return `${datePart}T${formattedTime} ${date.toLocaleString("sv-SE", { timeZoneName: "short" }).split(" ")[1]}`;
};
