import {
  LoginData,
  RegisterData,
  Employee,
  EmployeeData,
  Gig,
  GigData,
  Message,
  Company,
  CompanyData,
  PayCalculationResponse,
  HoursWorkedPerDay,
  AttendanceWithUrls,
} from "../constants/interfaces";

const API_URL =
  "https://managerize-ckbpcub2fse8bvcn.westeurope-01.azurewebsites.net/api";

const fetchApi = async <T>(
  endpoint: string,
  options: RequestInit = {},
): Promise<T> => {
  const url = `${API_URL}${endpoint}`;

  const defaultOptions: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    ...options,
  };

  try {
    const response = await fetch(url, defaultOptions);

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(`Error ${response.status}: ${errorMessage}`);
    }

    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      return await response.json();
    } else {
      const rawResponse = await response.text();
      return { message: rawResponse } as unknown as T; // Universal handling
    }
  } catch (error) {
    console.error("Fetch API Error:", error);
    throw error;
  }
};

export const getToken = (): string | null => {
  return localStorage.getItem("token");
};

const saveToken = (token: string): void => {
  localStorage.setItem("token", token);
};

const removeToken = (): void => {
  localStorage.removeItem("token");
};

export const registerUser = async (userData: RegisterData): Promise<void> => {
  return await fetchApi("/auth/register", {
    method: "POST",
    body: JSON.stringify(userData),
  });
};

export const loginUser = async (loginData: LoginData): Promise<string> => {
  const response = await fetchApi<{ token: string }>("/auth/login", {
    method: "POST",
    body: JSON.stringify(loginData),
  });

  if (response.token) {
    saveToken(response.token);
    return response.token;
  } else {
    console.error("No token returned from login");
    throw new Error("Login failed: No token returned");
  }
};

export const logoutUser = async (): Promise<void> => {
  removeToken();
};

export const createEmployee = async (
  employeeData: EmployeeData,
): Promise<Employee> => {
  return await fetchApi<Employee>("/employee", {
    method: "POST",
    body: JSON.stringify(employeeData),
  });
};

export const deleteEmployee = async (employeeId: string): Promise<void> => {
  await fetchApi(`/employee/${employeeId}`, {
    method: "DELETE",
  });
};

export const updateEmployee = async (
  employeeId: string,
  employeeData: EmployeeData,
): Promise<Employee> => {
  return await fetchApi<Employee>(`/employee/${employeeId}`, {
    method: "PUT",
    body: JSON.stringify(employeeData),
  });
};

export const getCompanyId = async (): Promise<number> => {
  return await fetchApi("/employee/company-id", {
    method: "GET",
  });
};

export const getAllEmployeesForLoggedInUser = async (): Promise<Employee[]> => {
  return await fetchApi<Employee[]>("/employee/all", {
    method: "GET",
  });
};

export const getEmployeeById = async (
  employeeId: string,
): Promise<Employee> => {
  return await fetchApi<Employee>(`/employee/${employeeId}`, {
    method: "GET",
  });
};

export const getHoursWorkedPerDayByEmployeeId = async (
  employeeId: string,
): Promise<HoursWorkedPerDay[]> => {
  const timeZoneOffsetMinutes = new Date().getTimezoneOffset();

  const url = `/attendance/hoursworkedbyday/${employeeId}?clientTimeZoneOffsetMinutes=${timeZoneOffsetMinutes}`;

  return await fetchApi<HoursWorkedPerDay[]>(url, {
    method: "GET",
  });
};

export const getAttendanceByEmployeeId = async (
  employeeId: string,
): Promise<AttendanceWithUrls[]> => {
  return await fetchApi<AttendanceWithUrls[]>(
    `/attendance/getattendance/${employeeId}`,
    {
      method: "GET",
    },
  );
};

export const calculateEmployeePay = async (
  employeeId: string,
  timePeriodType: string,
  startDate: Date | null = null,
  endDate: Date | null = null,
): Promise<PayCalculationResponse> => {
  const timeZoneOffsetMinutes = new Date().getTimezoneOffset();
  const endpoint =
    `/attendance/calculatepay/${employeeId}?timePeriodType=${timePeriodType}` +
    (startDate ? `&startDate=${startDate.toISOString().split("T")[0]}` : "") +
    (endDate ? `&endDate=${endDate.toISOString().split("T")[0]}` : "") +
    `&timeZoneOffsetMinutes=${timeZoneOffsetMinutes}`;

  return await fetchApi<PayCalculationResponse>(endpoint, {
    method: "GET",
  });
};

export const isClockedIn = async (employeeId: string): Promise<boolean> => {
  return await fetchApi<boolean>(`/attendance/isclockedin/${employeeId}`, {
    method: "GET",
  });
};

export const createGig = async (gigData: GigData): Promise<Gig> => {
  return await fetchApi<Gig>("/gig/create", {
    method: "POST",
    body: JSON.stringify(gigData),
  });
};

export const updateGig = async (
  gigId: number,
  gigData: GigData,
): Promise<Gig> => {
  return await fetchApi<Gig>(`/gig/update/${gigId}`, {
    method: "PUT",
    body: JSON.stringify(gigData),
  });
};

export const getAllGigsForLoggedInUser = async (): Promise<Gig[]> => {
  return await fetchApi<Gig[]>("/gig/all", {
    method: "GET",
  });
};

export const getUpcomingGigs = async (): Promise<Gig[]> => {
  return await fetchApi<Gig[]>("/gig/upcoming", {
    method: "GET",
  });
};

export const assignEmployeeToGig = async (
  gigId: number,
  employeeId: string,
): Promise<void> => {
  return await fetchApi(
    `/gig/assign-employee?gigId=${gigId}&employeeId=${employeeId}`,
    {
      method: "POST",
      body: JSON.stringify({ gigId, employeeId: String(employeeId) }),
    },
  );
};

export const unassignEmployeeFromGig = async (
  gigId: number,
  employeeId: string,
): Promise<void> => {
  return await fetchApi(
    `/gig/unassign-employee?gigId=${gigId}&employeeId=${employeeId}`,
    {
      method: "POST",
      body: JSON.stringify({ gigId, employeeId }),
    },
  );
};

export const getEmployeesForGig = async (
  gigId: number,
): Promise<Employee[]> => {
  return await fetchApi<Employee[]>(`/gig/${gigId}/employees`, {
    method: "GET",
  });
};

export const getMessagesForEmployeeAndCompany = async (
  employeeId: string,
): Promise<Message[]> => {
  return await fetchApi<Message[]>(`/message/${employeeId}`, {
    method: "GET",
  });
};

export const sendMessage = async (messageData: Message): Promise<void> => {
  await fetchApi("/message", {
    method: "POST",
    body: JSON.stringify(messageData),
  });
};

export const getCompanySettings = async (): Promise<Company> => {
  return await fetchApi<Company>("/settings", {
    method: "GET",
  });
};

export const updateCompanySettings = async (
  companyId: number,
  companyData: CompanyData,
): Promise<Company> => {
  return await fetchApi<Company>(`/settings/${companyId}`, {
    method: "PUT",
    body: JSON.stringify(companyData),
  });
};

export const generateResetPasswordToken = async (
  email: string,
): Promise<void> => {
  return await fetchApi("/auth/generateresetpasswordtoken", {
    method: "POST",
    body: JSON.stringify(email), // Pass the email as a plain string
  });
};

export const validateResetPasswordToken = async (
  email: string,
  token: string,
): Promise<void> => {
  return await fetchApi("/auth/validateresetpasswordtoken", {
    method: "POST",
    body: JSON.stringify({ email, token }),
  });
};

export const resetPassword = async (
  email: string,
  token: string,
  newPassword: string,
): Promise<void> => {
  return await fetchApi("/auth/resetpassword", {
    method: "POST",
    body: JSON.stringify({ email, token, newPassword }),
  });
};
