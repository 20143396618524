import React, { useEffect, useState } from "react";
import "./EmployeeDetailIsClockedIn.css";
import { isClockedIn } from "../../apiService/apiService";
import Loader from "../Loader/Loader";

interface EmployeeDetailIsClockedInProps {
  employeeId: string;
}

const EmployeeDetailIsClockedIn = ({
  employeeId,
}: EmployeeDetailIsClockedInProps) => {
  const [isClockedInStatus, setIsClockedInStatus] = useState<boolean | null>(
    null,
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchClockedInStatus = async () => {
      try {
        const status = await isClockedIn(employeeId);
        setIsClockedInStatus(status);
      } catch (err) {
        setError((err as Error).message);
      } finally {
        setLoading(false);
      }
    };

    fetchClockedInStatus();
  }, [employeeId]);

  return (
    <div className={`employee-status`}>
      <h3 className="card-title">Employee Status</h3>
      <div className="status-message">
        {loading ? (
          <Loader isLoading={loading} />
        ) : error ? (
          <div>Error: {error}</div>
        ) : (
          <span className={isClockedInStatus ? "clocked-in" : "clocked-out"}>
            {isClockedInStatus ? "Currently clocked in" : "Not clocked in"}
          </span>
        )}
      </div>
    </div>
  );
};

export default EmployeeDetailIsClockedIn;
