import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./OverviewEmployeeList.css";
import {
  getAllEmployeesForLoggedInUser,
  createEmployee,
} from "../../apiService/apiService";
import Loader from "../Loader/Loader";
import { Employee, EmployeeData } from "../../constants/interfaces";

const OverviewEmployeeList = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [employeeData, setEmployeeData] = useState<EmployeeData>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    dateOfBirth: "",
    gender: "Other",
    hireDate: "",
    jobTitle: "",
    paymentType: "Hourly",
    paymentAmount: 0,
    companyId: 0,
  });
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const fetchEmployees = async () => {
      setLoading(true);
      try {
        const data = await getAllEmployeesForLoggedInUser();
        setEmployees(data);
      } catch (error) {
        console.error("Failed to fetch employees:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEmployees();
  }, []);

  const handleAdd = () => {
    setEmployeeData({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      dateOfBirth: "",
      gender: "Other",
      hireDate: "",
      jobTitle: "",
      paymentType: "Hourly",
      paymentAmount: 0,
      companyId: 0,
    });
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitting(true);

    const employeeDataTyped: EmployeeData = {
      firstName: employeeData.firstName,
      lastName: employeeData.lastName,
      email: employeeData.email,
      phoneNumber: employeeData.phoneNumber,
      dateOfBirth: new Date(employeeData.dateOfBirth).toISOString(),
      gender: employeeData.gender,
      hireDate: new Date(employeeData.hireDate).toISOString(),
      jobTitle: employeeData.jobTitle,
      paymentType: employeeData.paymentType,
      paymentAmount: employeeData.paymentAmount,
    };

    try {
      await createEmployee(employeeDataTyped);
      setModalOpen(false);
      setLoading(true);
      const updatedEmployees = await getAllEmployeesForLoggedInUser();
      setEmployees(updatedEmployees);
    } catch (error) {
      console.error("Failed to create employee:", error);
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  const handleNavigateToEmployeeDetails = (id: string) => {
    navigate(`/dashboard/employeedetail/${id}`);
  };

  return (
    <div className="overview-employee-list">
      <header className="employee-list-header">
        <h2>Employee List</h2>
        <button className="add-button" onClick={handleAdd}>
          Add Employee
        </button>
      </header>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>DOB</th>
              <th>Gender</th>
              <th>Hire Date</th>
              <th>Job Title</th>
              <th>Payment Type</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {employees.map((employee, index) => (
              <tr
                key={`${employee.employeeId}-${index}`}
                onClick={() =>
                  handleNavigateToEmployeeDetails(employee.employeeId)
                }
              >
                <td>{employee.employeeId}</td>
                <td>
                  {employee.firstName} {employee.lastName}
                </td>
                <td>{employee.email}</td>
                <td>{employee.phoneNumber}</td>
                <td>
                  {new Date(employee.dateOfBirth).toLocaleDateString("en-CA")}
                </td>
                <td>{employee.gender}</td>
                <td>
                  {new Date(employee.hireDate).toLocaleDateString("en-CA")}
                </td>
                <td>{employee.jobTitle}</td>
                <td>{employee.paymentType}</td>
                <td>{employee.status}</td>
                <td>
                  <span
                    className="view-details"
                    onClick={() =>
                      handleNavigateToEmployeeDetails(employee.employeeId)
                    }
                  >
                    View
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Loader isLoading={loading} />

      {modalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <span className="close-x" onClick={handleModalClose}>
              <img src="/icons/x-icon.svg" alt="Close" />
            </span>
            <h2>Add Employee</h2>
            <form onSubmit={handleSubmit}>
              <h3>Personal Information</h3>
              <div className="label-and-input-pair">
                <label>First Name:</label>
                <input
                  type="text"
                  value={employeeData.firstName}
                  onChange={(e) =>
                    setEmployeeData({
                      ...employeeData,
                      firstName: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div className="label-and-input-pair">
                <label>Last Name:</label>
                <input
                  type="text"
                  value={employeeData.lastName}
                  onChange={(e) =>
                    setEmployeeData({
                      ...employeeData,
                      lastName: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div className="label-and-input-pair">
                <label>Email:</label>
                <input
                  type="email"
                  value={employeeData.email}
                  onChange={(e) =>
                    setEmployeeData({ ...employeeData, email: e.target.value })
                  }
                  required
                />
              </div>
              <div className="label-and-input-pair">
                <label>Phone Number:</label>
                <input
                  type="text"
                  value={employeeData.phoneNumber}
                  onChange={(e) =>
                    setEmployeeData({
                      ...employeeData,
                      phoneNumber: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div className="label-and-input-pair">
                <label>Date of Birth:</label>
                <input
                  type="date"
                  value={employeeData.dateOfBirth}
                  onChange={(e) =>
                    setEmployeeData({
                      ...employeeData,
                      dateOfBirth: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div className="label-and-input-pair">
                <label>Gender:</label>
                <select
                  value={employeeData.gender}
                  onChange={(e) =>
                    setEmployeeData({ ...employeeData, gender: e.target.value })
                  }
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <h3>Employment Information</h3>
              <div className="label-and-input-pair">
                <label>Hire Date:</label>
                <input
                  type="date"
                  value={employeeData.hireDate}
                  onChange={(e) =>
                    setEmployeeData({
                      ...employeeData,
                      hireDate: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div className="label-and-input-pair">
                <label>Job Title:</label>
                <input
                  type="text"
                  value={employeeData.jobTitle}
                  onChange={(e) =>
                    setEmployeeData({
                      ...employeeData,
                      jobTitle: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div className="label-and-input-pair">
                <label>Payment Type:</label>
                <select
                  value={employeeData.paymentType}
                  onChange={(e) =>
                    setEmployeeData({
                      ...employeeData,
                      paymentType: e.target.value,
                    })
                  }
                >
                  <option value="Hourly">Hourly</option>
                  <option value="Salary">Salary</option>
                </select>
              </div>
              <div className="label-and-input-pair">
                <label>Payment Amount:</label>
                <input
                  type="number"
                  value={employeeData.paymentAmount}
                  onChange={(e) =>
                    setEmployeeData({
                      ...employeeData,
                      paymentAmount: Number(e.target.value),
                    })
                  }
                  required
                />
              </div>
              <button type="submit" disabled={submitting}>
                {submitting ? "Creating..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default OverviewEmployeeList;
