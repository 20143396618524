import React, { useState } from "react";
import { Calendar } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useNavigate } from "react-router-dom";
import "./EmployeeDetailViewMoreHoursWorkedTable.css";
import { Value } from "react-calendar/dist/cjs/shared/types";
import {
  AttendanceWithHoursWorked,
  AttendanceWithUrls,
} from "../../constants/interfaces";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import {
  formatIsoStringWithAmPm,
  formatISOToUKDay,
} from "../../utilities/utilities";

interface EmployeeDetailViewMoreHoursWorkedTableProps {
  attendanceData: AttendanceWithUrls[];
  employeeId: string;
}

const EmployeeDetailViewMoreHoursWorkedTable = ({
  attendanceData = [],
  employeeId,
}: EmployeeDetailViewMoreHoursWorkedTableProps) => {
  const [calendarDate, setCalendarDate] = useState<Date | null>(null);
  const [selectedAttendance, setSelectedAttendance] = useState<
    AttendanceWithHoursWorked[]
  >([]);
  const [mapVisible, setMapVisible] = useState<{
    show: boolean;
    lat: number;
    lon: number;
  }>({ show: false, lat: 0, lon: 0 });
  const navigate = useNavigate();

  const attendanceMap = attendanceData.reduce<
    Record<string, AttendanceWithHoursWorked[]>
  >((acc, attendance) => {
    const dateKey = formatISOToUKDay(attendance.clockIn);
    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }

    const clockInDate = new Date(attendance.clockIn);
    const clockOutDate = attendance.clockOut
      ? new Date(attendance.clockOut)
      : null;
    const hoursWorked = clockOutDate
      ? (
          (clockOutDate.getTime() - clockInDate.getTime()) /
          (1000 * 60 * 60)
        ).toFixed(2)
      : "N/A";

    acc[dateKey].push({ ...attendance, hoursWorked });

    return acc;
  }, {});

  const handleDateChange = (value: Value) => {
    if (value instanceof Date) {
      setCalendarDate(value);
      const selectedDate = value.toLocaleDateString("en-GB");
      setSelectedAttendance(attendanceMap[selectedDate] || []);
    }
  };

  const totalHoursWorked = selectedAttendance
    .reduce((total, attendance) => {
      const hours = parseFloat(attendance.hoursWorked);
      return total + (isNaN(hours) ? 0 : hours);
    }, 0)
    .toFixed(2);

  const handleBackClick = () => {
    navigate(`/dashboard/employeedetail/${employeeId}`);
  };

  const handleLocationClick = (lat: number, lon: number) => {
    setMapVisible({ show: true, lat, lon });
  };

  const closeMapModal = () => {
    setMapVisible({ show: false, lat: 0, lon: 0 });
  };

  return (
    <div className="employee-attendance-details-table">
      <button onClick={handleBackClick} className="back-button">
        <img src="/icons/back.svg" alt="Back" className="back-icon" />
      </button>
      <h2 className="attendance-title">Attendance</h2>
      <div className="calendar-container">
        <Calendar
          className="custom-calendar"
          onChange={handleDateChange}
          value={calendarDate}
        />
      </div>
      {attendanceData.length === 0 ? (
        <p className="no-records">No attendance records found.</p>
      ) : selectedAttendance.length > 0 ? (
        <>
          <div className="attendance-table-container">
            <table className="attendance-table">
              <thead>
                <tr>
                  <th>Clock In</th>
                  <th>Clock Out</th>
                  <th>Hours Worked</th>
                  <th>Clock In Location</th>
                  <th>Clock Out Location</th>
                  <th>Signature</th>
                </tr>
              </thead>
              <tbody>
                {selectedAttendance.map((attendance) => {
                  return (
                    <tr key={attendance.attendanceId}>
                      <td>{formatIsoStringWithAmPm(attendance.clockIn)}</td>
                      <td>
                        {attendance.clockOut
                          ? formatIsoStringWithAmPm(attendance.clockOut)
                          : "Currently clocked in..."}
                      </td>
                      <td>{attendance.hoursWorked}</td>
                      <td>
                        {attendance.clockInLatitude &&
                        attendance.clockInLongitude ? (
                          <>
                            <img
                              src="https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png"
                              alt="Clock In Location"
                              style={{ width: "20px", cursor: "pointer" }}
                              onClick={() =>
                                handleLocationClick(
                                  attendance.clockInLatitude,
                                  attendance.clockInLongitude,
                                )
                              }
                            />
                          </>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td>
                        {attendance.clockOutLatitude &&
                        attendance.clockOutLongitude ? (
                          <>
                            <img
                              src="https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png"
                              alt="Clock Out Location"
                              style={{ width: "20px", cursor: "pointer" }}
                              onClick={() =>
                                handleLocationClick(
                                  attendance.clockOutLatitude,
                                  attendance.clockOutLongitude,
                                )
                              }
                            />
                          </>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td>
                        {attendance.signature && (
                          <img
                            src={attendance.signatureUrl || ""}
                            alt="Signature"
                            style={{ width: "50px", height: "auto" }}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="total-hours">
            <strong>Total: {totalHoursWorked} hours</strong>
          </div>
        </>
      ) : (
        <p className="no-records">
          No attendance records found for the selected date.
        </p>
      )}

      {/* Modal to show large map */}
      {mapVisible.show && (
        <div className="map-modal" onClick={closeMapModal}>
          <div
            className="map-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <MapContainer
              center={[mapVisible.lat, mapVisible.lon]}
              zoom={15}
              style={{ width: "100%", height: "400px" }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker
                position={[mapVisible.lat, mapVisible.lon]}
                icon={
                  new L.Icon({
                    iconUrl:
                      "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png", // Leaflet's default icon
                    iconSize: [25, 41],
                    iconAnchor: [12, 41],
                    popupAnchor: [1, -34],
                    shadowSize: [41, 41],
                  })
                }
              >
                <Popup>Location</Popup>
              </Marker>
            </MapContainer>
            <button onClick={closeMapModal} className="close-modal">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeDetailViewMoreHoursWorkedTable;
