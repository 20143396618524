import React, { useEffect, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import { getAttendanceByEmployeeId } from "../../apiService/apiService";
import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";
import EmployeeDetailViewMoreHoursWorkedTable from "../../components/EmployeeDetailViewMoreHoursWorkedTable/EmployeeDetailViewMoreHoursWorkedTable";
import Loader from "../../components/Loader/Loader";
import "./EmployeeDetailViewMoreHoursWorked.css";
import { AttendanceWithUrls } from "../../constants/interfaces";

const EmployeeDetailViewMoreHoursWorked = () => {
  const { employeeId } = useParams<{ employeeId: string }>();
  const [attendanceData, setAttendanceData] = useState<AttendanceWithUrls[]>(
    [],
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!employeeId) {
      setError("Employee ID is missing");
      setLoading(false);
      return;
    }

    const fetchAttendance = async () => {
      try {
        setLoading(true);
        const data = await getAttendanceByEmployeeId(employeeId);
        setAttendanceData(data);
      } catch (err) {
        console.error("Error fetching attendance data:", err);
        setError("Error fetching attendance data");
      } finally {
        setLoading(false);
      }
    };

    fetchAttendance();
  }, [employeeId]);

  if (loading) return <Loader isLoading={loading} />;
  if (error) return <div>{error}</div>;

  if (!employeeId) {
    return <Navigate to="/error" />;
  }

  return (
    <div className="employee-detail-attendance">
      <DashboardNavbar />
      <div className="content">
        <EmployeeDetailViewMoreHoursWorkedTable
          attendanceData={attendanceData}
          employeeId={employeeId}
        />
      </div>
    </div>
  );
};

export default EmployeeDetailViewMoreHoursWorked;
