import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Ensure TypeScript recognizes the type of `root` as HTMLElement
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// Optionally, you can log web vitals to the console or send to an analytics endpoint
reportWebVitals();
