import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./FAQ.css";

interface FAQItem {
  question: string;
  answer: React.ReactNode;
}

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleAnswer = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqData: FAQItem[] = [
    {
      question: "What is Managerize?",
      answer:
        "Managerize is a workforce management software designed to help businesses streamline employee scheduling, time tracking, and reporting.",
    },
    {
      question: "Who can use Managerize?",
      answer:
        "Managerize is intended for businesses and organizations of all sizes that need to manage and optimize their workforce.",
    },
    {
      question: "How do I create an account?",
      answer:
        "To create an account, simply click the 'Sign Up' button on our homepage, provide your business details, and set up your account.",
    },
    {
      question: "What services does Managerize offer?",
      answer:
        "Managerize offers a range of features including employee scheduling, time tracking, payroll integration, and detailed reports to help you manage your workforce efficiently.",
    },
    {
      question: "How do I manage my subscription?",
      answer:
        "You can manage your subscription through your account settings. You can upgrade, downgrade, or cancel your subscription at any time.",
    },
    {
      question: "Is there a free trial?",
      answer:
        "Yes, we offer a 14-day free trial for new users. During this trial period, you can explore all the features of Managerize with no obligation.",
    },
    {
      question: "How do I get support?",
      answer:
        "If you need assistance, you can visit our Support Center or contact our customer support team via email at support@managerize.com.",
    },
    {
      question: "Is my data secure with Managerize?",
      answer:
        "Yes, we take data security seriously. We use industry-standard encryption methods to protect your data and ensure that it is handled securely.",
    },
    {
      question: "Can I integrate Managerize with other software?",
      answer:
        "Yes, Managerize integrates with a number of third-party tools such as payroll systems, accounting software, and HR platforms.",
    },
    {
      question: "How do I cancel my account?",
      answer:
        "You can cancel your account by going to the 'Account Settings' page and selecting 'Cancel Subscription.'",
    },
    {
      question: "Do you offer refunds?",
      answer:
        "We offer a refund within 30 days of your subscription payment if you're not satisfied with our service.",
    },
    {
      question: "How can I contact you?",
      answer: (
        <p>
          You can reach us at:
          <br />
          <strong>Managerize</strong>
          <br />
          Email:{" "}
          <a href="mailto:contact@managerize.com">contact@managerize.com</a>
          <br />
          Phone: [Insert Phone Number]
          <br />
          Address: [Insert Address]
        </p>
      ),
    },
  ];

  return (
    <div className="FAQ">
      <div className="banner">
        <img
          src="/images/header/banner.png"
          alt="Banner"
          className="banner-image"
        />
      </div>

      <div className="FAQ-content">
        <Link to="/">
          <img src="/images/logo/logo.svg" alt="Logo" className="logo" />
        </Link>

        <div className="FAQ-container">
          <header>FAQ</header>
          <section>
            {faqData.map((item, index) => (
              <div key={index}>
                <h2
                  onClick={() => toggleAnswer(index)}
                  className="faq-question"
                >
                  {item.question}
                  <span
                    className={`faq-toggle-icon ${openIndex === index ? "open" : ""}`}
                  >
                    {openIndex === index ? "−" : "+"}
                  </span>
                </h2>
                {openIndex === index && (
                  <p className="faq-answer">{item.answer}</p>
                )}
                <div className="faq-separator"></div> {/* Separator line */}
              </div>
            ))}
          </section>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
