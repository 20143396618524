import React from "react";
import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";
import OverviewEmployeeList from "../../components/OverviewEmployeeList/OverviewEmployeeList";
//import OverviewUpcomingEvents from '../../components/OverviewUpcomingEvents/OverviewUpcomingEvents';
import OverviewGigs from "../../components/OverviewGigs/OverviewGigs";
import OverviewChat from "../../components/OverviewChat/OverviewChat";
import "./Overview.css";

const Dashboard = () => {
  return (
    <div className="overview">
      <DashboardNavbar />
      <div className="content">
        <OverviewEmployeeList />
        <OverviewGigs />
        <OverviewChat />
      </div>
    </div>
  );
};

export default Dashboard;
